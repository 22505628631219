import { TextField } from '@mui/material';
import Container from '@mui/material/Container';
import Image from 'next/image';
import React, { useState } from 'react';
import { H2 } from 'src/base/texts/H2';
import { FeatureBox } from 'src/components/features/FeatureBox';
import { NextPageWithLayout } from 'src/utils/types';
import { MarbleButton } from 'src/base/inputs/Button';
import { H1 } from 'src/base';
import withFreeLayout from 'src/components/layout/FreeLayout';
import { useRouter } from 'next/router';
import { MarbleText } from 'src/base/texts/Text';
import { track } from 'src/deps/mixpanel';

const Free: NextPageWithLayout = () => {
  const router = useRouter();
  const [email, setEmail] = useState('');
  const waitlistSubmit = () => {
    const refcode: string = router.query.rc as string;

    track('Free Waitlist Regisered', {
      email,
      refcode,
    });

    if (!refcode || refcode === '') {
      router.push({
        pathname: '/free/waitlist',
        query: {
          email,
        },
      });
    } else {
      router.push({
        pathname: '/free/waitlist',
        query: {
          email,
          rc: refcode,
        },
      });
    }
  };
  return (
    <div>
      <div
        style={{
          padding: 0,
          backgroundImage: 'url("/img/home3.webp")',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div
          style={{
            paddingBottom: 150,
            paddingTop: 150,
            backdropFilter: 'blur(15px)',
            color: 'white',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <H1>Free Property Management Assistant</H1>
          <H2>Stop paying 10% to Property Managers</H2>
          <div
            style={{
              display: 'flex',
              maxWidth: 600,
              width: '100%',
              marginTop: 20,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <div style={{ width: '100%', marginRight: 5, textAlign: 'left' }}>
              <TextField
                placeholder="Email"
                style={{
                  backgroundColor: 'white',
                  borderRadius: 8,
                  border: 'none',
                }}
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div
              style={{
                minWidth: 175,
                paddingBottom: 5,
                height: 60,
                fontWeight: 'bold',
              }}
            >
              <MarbleButton
                title="Get early access"
                sx={{ height: '60px' }}
                onClick={() => waitlistSubmit()}
              />
            </div>
          </div>
        </div>
      </div>

      <Container style={{ marginTop: 100 }}>
        <H2 style={{ textAlign: 'center' }}>What do we cover?</H2>
        <FeatureBox
          header="End to End Tenant Placement"
          descr="Everything from listings to self-tours to applications. Our placement service finds you tenants in 2.5 weeks on average."
          reverseImgOrder
          moreInfo={{
            text: 'Learn more',
            link: '/free/self-touring',
          }}
        >
          <Image
            style={{ borderRadius: 5 }}
            width={540}
            height={313}
            src="/img/tour.webp"
            alt="People touring a home"
          />
        </FeatureBox>

        <FeatureBox
          header="24/7 Maintenance Dispatch"
          descr="Never field a maintenance call again. Our team is always on and ready to handle any repair."
          moreInfo={{
            text: 'Learn more',
            link: '/free/maintenance',
          }}
        >
          <Image
            style={{ borderRadius: 5 }}
            width={540}
            height={313}
            src="/img/handyman.webp"
            alt="Handyman fixing a window for a house"
          />
        </FeatureBox>

        <FeatureBox
          header="Digital Rent Collection"
          descr="Lose the spreadsheet. Track all rental income and bills through Marble's app and website."
          reverseImgOrder
        >
          <Image
            style={{ borderRadius: 5 }}
            width={540}
            height={313}
            src="/img/digital-management.webp"
            alt="Website portraying digital management"
          />
        </FeatureBox>
      </Container>
      <Container
        style={{
          marginTop: 75,
          maxWidth: 700,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <H2 style={{ marginBottom: 30 }}>Marble Fees</H2>
        <FeeRow title="Monthly Fee" value="$0 / month" />
        <FeeRow title="Maint. Markup (optional)" value="$20 / completion" />
        <FeeRow title="Leasing Fee" value="Included" />
        <FeeRow title="Renewal Fee" value="Included" />
        <FeeRow title="ACH Fee *" value="$3" />
        <FeeRow title="Card Fee" value="3%" />
        <div style={{ width: '100%', maxWidth: 600, marginTop: 20 }}>
          <MarbleText>
            * ACH fee can be charged to landlord or renter
          </MarbleText>
        </div>
      </Container>
      <Container style={{ marginTop: 100, maxWidth: 800 }}>
        <H2 style={{ textAlign: 'center' }}>How do we make money?</H2>
        <MarbleText style={{ marginTop: 30 }}>
          Marble makes money through application revenue, maintenance markups,
          and payment processing fees while reducing costs through automation
          with ChatGPT.
        </MarbleText>
      </Container>
    </div>
  );
};

const FeeRow = ({ title, value }) => (
  <div
    style={{
      maxWidth: 600,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 20,
      width: '100%',
    }}
  >
    <MarbleText style={{ fontSize: 22, fontWeight: '500', width: 200 }}>
      {title}
    </MarbleText>
    <MarbleText style={{ fontSize: 22, fontWeight: 'bold', width: 130 }}>
      {value}
    </MarbleText>
  </div>
);

export default withFreeLayout(Free);
