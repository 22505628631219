import React from 'react';
import Navigation from 'src/base/nav/Navigation';
import CopyrightFooter from 'src/components/layout/footer/CopyrightFooter';
import AdsHead from 'src/components/layout/head/AdsHead';
import EtcHead from 'src/components/layout/head/EtcHead';
import TitleDescriptionHead from 'src/components/layout/head/TitleDescriptionHead';
import TrackingScript from 'src/components/layout/head/TrackingScript';
import { Snack } from 'src/components/layout/Snack';
import { NextPageWithLayout } from 'src/utils/types';

const Layout = ({ children }) => (
  <>
    <AdsHead />
    <EtcHead />
    <TitleDescriptionHead
      title="Marble - Free Virtual Rental Management"
      description="Marble helps owners with the most stressful parts of renting out their home. We take care of tenant placement and 24/7 maintenance, all for a $65/month flat fee."
    />
    <Navigation banner homePath="/free" tabs={[]} actions={[]} />
    <TrackingScript />
    {children}
    <Snack />
    <CopyrightFooter />
  </>
);

export default function withFreeLayout<Props = {}>(
  page: NextPageWithLayout<Props>,
) {
  page.getLayout = function getLayout(pageElement: React.ReactElement) {
    return <Layout>{pageElement}</Layout>;
  };
  return page;
}
